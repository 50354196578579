const getters = {
  session: state => state.user.session,
  avatar: state => state.user.avatar,
  name: state => state.user.name,
  roles: state => state.user.roles,
  menus: state => state.permission.menus,
  authoritys: state => state.permission.authoritys,
  stationInfo: state => state.archive.station.station
}
export default getters
