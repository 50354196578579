<template>
  <div class="notification" :class="{ active: !showNotify }" style="height: 100%">
    <div v-if="!isLogin" class="notification-box" @click="hide = false">
      <span class="el-icon-bell" :class="{ reddian: !showNotify }"></span>
    </div>
    <div class="message-box" :class="{ hide: hide }">
      <div class="box_header">
        <span>通知</span>
        <span class="close" @click="hide = true">收起</span>
      </div>
      <div class="mes_list">
        <template v-for="(item, index) in list">
          <div v-if="!item.hide" :key="item.id" class="mes-item">
            <div class="el-icon-close" @click="closeMes(index)"></div>
            <div class="station_name">
              {{ item.station_name }}
            </div>
            <div v-for="early in item.earle" class="early-info mes_info">
              {{ early.end_time }}<span style="color: rgb(255, 131, 131)">{{ early.factor_name }}</span
              >{{ early.fault_type_name }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { commonCommonEarly } from '@/api/common'
import { setItem, getItem, removeItem } from '../../utils/storage'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      list: [],
      timer: null,
      hide: true
    }
  },
  computed: {
    showNotify() {
      return this.list.every((item) => {
        return item.hide
      })
    },
    isLogin() {
      return '/login' == this.$route.path
    }
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer)
    }
    this.getData()
    this.timer = setInterval(() => {
      this.getData()
    }, 300000)
  },
  watch: {
    $route(to, from) {
      if (from.path == '/login') {
        // if (this.timer) {
        //   clearInterval(this.timer)
        // }
        this.hide = false
        this.getData()
        // this.timer = setInterval(() => {
        //   this.getData()
        // }, 3600000)
      }
    }
  },
  methods: {
    closeMes(index) {
      this.list[index].hide = true
      let early = []
      // 如果有存early
      if (getItem('early')?.length) {
        early = [...getItem('early'), this.list[index].id]
      } else {
        // 没存
        early = [this.list[index].id]
      }
      setItem('early', early)
    },
    async getData() {
      if (!getItem('session')) return
      // setInterval(() => {
      //   removeItem('early')
      //   location.reload()
      // }, 3600000)
      const _this = this
      let res = await commonCommonEarly()
      res = res.map((item) => {
        return {
          ...item,
          hide: false
        }
      })
      let time = dayjs().unix()
      if (getItem('earlyTime') == undefined || time - getItem('earlyTime') > 3600) {
        // 如果时间超过一小时或者没有上次存储时间
        removeItem('early')
        this.list = res
        setItem('earlyTime', time)
      } else {
        // 没超过一小时
        this.list = res
        let earlyList = getItem('early') || []
        for (let i of this.list) {
          if (earlyList.includes(i.id)) {
            i.hide = true
          } else {
            this.hide = false
          }
        }
        // this.list.forEach((item, index) => {
        //   if (earlyList.includes(item.id)) {
        //     this.list[index].hide = true
        //   }
        // })
      }
      // let hide = this.list.every((item) => {
      //   return item.hide
      // })
      // this.hide = hide
      // this.list.forEach((e, i) => {
      //   let str = ``
      //   e.earle.forEach((item) => {
      //     str += `<div style='color:white' class='early-info'>${item.end_time}<span style="color: rgb(255, 131, 131);">${item.factor_name}</span>${item.fault_type_name}</div>`
      //   })
      //   if (!e.hide) {
      //     setTimeout(() => {
      //       this.$notify({
      //         dangerouslyUseHTMLString: true,
      //         title: e.station_name,
      //         message: str,
      //         duration: 0,
      //         onClose(res) {
      //           let early = []
      //           // 如果有存early
      //           if (getItem('early')?.length) {
      //             early = [...getItem('early'), e.id]
      //           } else {
      //             // 没存
      //             early = [e.id]
      //           }
      //           setItem('early', early)
      //           _this.list[i].hide = true
      //         }
      //       })
      //     }, 0)
      //   }
      // })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('@/assets/css/monitor.less');

.notification {
  overflow: hidden;
  position: relative;
  //   filter: drop-shadow(0px 0px 50px rgba(255, 75, 75,0.2));
  &::after {
    content: '';
    position: absolute;
    transition: box-shadow 0.3s ease;
    height: 100%;
    width: 1px;
    z-index: 10000;
    // box-shadow: 10px 0px 60px 60px rgba(255, 0, 0, 0.3);
    left: 0px;
    top: 0;
    // animation: shadowAnimation 1s infinite alternate;
  }
  &::before {
    content: '';
    position: absolute;
    transition: box-shadow 0.3s ease;
    height: 100%;
    width: 1px;
    z-index: 10000;
    // box-shadow: 10px 0px 60px 60px rgba(255, 0, 0, 0.3);
    right: 0px;
    top: 0;
  }
}
.notification-box {
  position: absolute;
  width: 30px;
  height: 40px;
  border-radius: 8px 0 0 8px;
  right: 0;
  top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  border: 2px solid @border-color;
  background-color: @bg-color;
  border-right: none;
  cursor: pointer;
  .el-icon-bell {
    font-size: 18px;
    font-weight: 800;
    color: @theme-color;
  }
  .reddian {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      height: 5px;
      width: 5px;
      background: rgb(255, 51, 51);
      border-radius: 50px;
      top: -5px;
      right: 0px;
    }
  }
}

.message-box {
  position: absolute;
  transition: all 0.4s;
  width: 400px;
  height: 700px;
  border-radius: 8px 0 0 8px;
  right: 0;
  top: 90px;
  z-index: 10001;
  background-color: @bg-color;
  border: 2px solid @border-color;
  border-right: none;
  overflow: hidden;
  .box_header {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding: 15px 15px;
    color: @font-color;
    border-bottom: 1px solid @border-color;
    background: @th-color;
    .close {
      font-weight: 400;
      font-size: 15px;
      cursor: pointer;
      &:hover {
        color: @theme-color;
      }
    }
  }
  .mes_list {
    height: 640px;
    overflow: auto;
    padding: 0 20px;
    .mes-item {
      position: relative;
      padding: 15px 0;
      border-bottom: 1px solid @border-color;
      .el-icon-close {
        cursor: pointer;
        position: absolute;
        right: 5px;
        top: 15px;
        color: #969696;
        &:hover {
          color: @font-color;
        }
      }
      &:last-child {
        border: none;
      }
      .station_name {
        margin-bottom: 5px;
        color: @theme-color;
      }
      .mes_info {
        font-size: 14px;
      }
    }
  }
}
.notification .hide {
  right: -401px;
}
.active {
  &::after {
    animation: shadowAnimation 1s infinite alternate;
  }
  &::before {
    animation: shadowAnimation 1s infinite alternate;
  }
}
@keyframes shadowAnimation {
  0% {
    box-shadow: 10px 0px 60px 60px rgba(255, 0, 0, 0);
  }
  50% {
    box-shadow: 10px 0px 60px 60px rgba(255, 0, 0, 0.4);
  }
  100% {
    box-shadow: 10px 0px 60px 60px rgba(255, 0, 0, 0);
  }
}
.early-info {
  color: @font-color;
}
</style>
