const storageHost = "";
const apiHost = "";
const wsHost = ""
const guideBaseUrl = ''

export default {
  host: apiHost,
  storage: storageHost,
  wsHost: wsHost,
  mapKey: "3ZBBZ-DLVCG-ASLQX-QP7GW-FEY4O-UWF4M",
  guideBaseUrl
};
