import Vue from "vue";
import VueRouter from "vue-router";
import config from "@/config";

import common from "@/router/authorize";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: "/",
      redirect: "/home",
      component: () => import("../layout/Content.vue"),
      children: common,
    },
  ],
});

export default router;
