import Content from '@/layout/Content'

export default {
  path: '/sys/env',
  meta: { title: '环境移动制法系统' },
  component: () => import('@/layout/Content.vue'),
  redirect: '/sys/env/company',
  children: [
    {
      path: '/sys/env/company',
      meta: { title: '企业信息管理' },
      component: Content,
      redirect: '/sys/env/company/list',
      children: [
        {
          path: '/sys/env/company/list',
          component: () => import('@/views/env/company/list'),
          meta: { title: '企业信息列表' }
        },
        {
          path: '/sys/env/company/import',
          component: () => import('@/views/env/company/import'),
          meta: { title: '企业信息导入' }
        }
      ]
    },
    {
      path: '/sys/env/user',
      meta: { title: '队员信息管理' },
      component: Content,
      redirect: '/sys/env/user/list',
      children: [
        {
          path: '/sys/env/user/list',
          component: () => import('@/views/env/user/list'),
          meta: { title: '队员信息列表' }
        },
      ]
    },
    {
      path: '/sys/env/inspect',
      meta: { title: '监察任务管理' },
      component: Content,
      redirect: '/sys/env/inspect/list',
      children: [
        {
          path: '/sys/env/inspect/list',
          component: () => import('@/views/env/inspect/list'),
          meta: { title: '任务指派列表' }
        },
        {
          path: '/sys/env/inspect/random',
          component: () => import('@/views/env/inspect/random'),
          meta: { title: '双随机抽查' }
        }
      ]
    },
    {
      path: '/sys/env/sampleTask',
      meta: { title: '抽查任务' },
      component: Content,
      redirect: '/sys/env/sampleTask/list',
      children: [
        {
          path: '/sys/env/sampleTask/list',
          component: () => import('@/views/env/sampleTask/list'),
          meta: { title: '任务清单' }
        }
      ]
    }
  ]
}
