// 查找是否存在此路由
export function hasRoute(route, path) {
  for (let i of route) {
    if (i.url == path) {
      return true
    } else if (i.child) {
      if (hasRoute(i.child, path)) {
        return true
      }
    }
  }
  return false
}
// 深拷贝路由
export function deepCopy(obj) {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  const copy = Array.isArray(obj) ? [] : {}

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      copy[key] = deepCopy(obj[key])
    }
  }

  return copy
}

// 路由对比删除
export function routeInit(allroute, route) {
  for (let i = 0; i < allroute.length; i++) {
    if (!hasRoute(route, allroute[i].path)) {
      allroute.splice(i, 1, { path: 'notRoute' }) // 删除匹配项
      continue
    }
    if (allroute[i].children) {
      routeInit(allroute[i].children, route) // 递归处理子节点
    }
  }
}

function getRedirect(menu) {
  let reds = []
  for (let i = 0; i < menu.length; i++) {
    if (menu[i].child && menu[i].child.length > 0) {
      if (menu[i].child[0].child.length == 0 || !menu[i].child[0].child) {
        reds.push(menu[i].child[0].url)
      } else {
        reds.push(menu[i].child[0].child[0].url)
      }
    }
  }
  return reds
}
// 设置路由重定向
export function setDefaultRoute(routes, menu) {
  let reds = getRedirect(menu)
  routes.forEach((v, i) => {
    if (v.children && v.children.length > 0) {
      for (let j = 0; j < v.children.length; j++) {
        if (reds.includes(v.children[j].path)) {
          v.redirect = v.children[j].path
          break
        }
      }
    }
  })
}
