import router from '../router'
import store from '../store'
import { Message } from 'element-ui'
import { getSession } from '@/utils/auth'
import Vue from 'vue'
// 按钮权限控制
Vue.prototype.$auth = (authname) => store.state.permission.authoritys.includes(authname)

const whiteList = ['/login', '/register']

router.beforeEach((to, from, next) => {
  if (getSession()) {
    if (to.meta.title) document.title = to.meta.title
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      // 如果登录之后未获取用户信息
      // if (store.getters.roles.length === 0) {
      if (!store.state.user.info.id) {
        // 则重新获取登录用户信息
        store
          .dispatch('GetInfo')
          .then(() => {
            store.dispatch('GenerateRoutes').then((accessRoutes) => {
              router.addRoute(accessRoutes)
              router.addRoute({
                path: '*',
                meta: { title: '404' },
                component: () => import('@/views/error/404.vue')
              })
              next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
            })
          })
          .catch((error) => {
            store.dispatch('LogOut').then(() => {
              Message.error(error.message)
              next({ path: '/' })
            })
          })
      } else {
        next()
      }
    }
  } else {
    // 没有session
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
    }
  }
})

router.afterEach((to, from) => {})
