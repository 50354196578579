export default [
  {
    path: '/home',
    meta: { title: '首页' },
    component: () => import('@/views/authorize/home/HomeIndex.vue')
  },
  {
    path: '/login',
    meta: { title: '登录' },
    component: () => import('@/views/authorize/login/LoginIndex.vue')
  },
  {
    path: '/register',
    meta: { title: '注册' },
    component: () => import('@/views/authorize/login/register.vue')
  },
  {
    path: '/profile',
    component: () => import('@/views/system/user/index.vue'),
    name: 'Profile',
    meta: { title: '个人中心', icon: 'user' }
  },
  {
    path: '/version',
    component: () => import('@/views/system/version/index.vue'),
    name: 'version',
    meta: { title: '版本记录', icon: 'user' }
  }
]
