import { setItem, getItem } from '@/utils/storage'

export default {
  namespaced: true,
  state: {
    station_id: getItem('station_id'),
    station_name: getItem('station_name'),
    station_status: getItem('station_status')
  },
  mutations: {
    SET_STATION_ID(state, value) {
      state.station_id = value
      setItem('station_id', value)
    },
    SET_STATION_NAME(state, value) {
      state.station_name = value
      setItem('station_name', value)
    },
    SET_STATION_STATUS(state, value) {
      state.station_status = value
      setItem('station_status', value)
    }
  }
}
