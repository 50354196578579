export default {
  path: '/sys/big-screen',
  meta: { title: '调度指挥中心信息系统' },
  redirect: 'index',
  component: () => import('@/views/dataView/index.vue'),
  children: [
    {
      path: '/sys/big-screen/index',
      meta: { title: '分布展示' },
      component: () => import('@/views/dataView/index/index.vue')
    },
    {
      path: '/sys/big-screen/data-anal',
      meta: { title: '数据分析' },
      component: () => import('@/views/dataView/dataAnal/index.vue')
    },
  ]
}
