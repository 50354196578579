<template>
  <Notification>
    <router-view></router-view>
  </Notification>
</template>

<script>
import Notification from './component/notification.vue'
export default {
  components: {
    Notification
  }
}
</script>

<style lang="less">
html {
  height: 100%;
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    #app {
      height: 100%;

      margin: 0;
      padding: 0;
    }
  }
}
.el-header,
.el-footer {
  background-color: #b3c0d1;
}

.el-aside {
  background-color: rgb(250, 250, 250);
  color: #333;
  text-align: center;
  line-height: 200px;
}
.el-container {
  height: 100%;
}
</style>
