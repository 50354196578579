const SET_COMPANY = 'set_company'
const GET_COMPANY = 'get_company'
const SET_INFO_KEY = 'set_info_key'
const GET_INFO_KEY = 'get_info_key'
const SET_STATION = 'set_station'
const GET_STATION = 'get_station'

const SESSION = 'session'
const BASEURL = '/admin'
const SYSTEMS = [
  {
    key: 'archive',
    name: '企业信息管理系统',
    icon: 'icon-icon_xinyong_xianxing_jijin-2',
    color: '#3096ea',
    path: '/sys/archive'
  },

  {
    key: 'basic',
    name: '基础数据管理系统',
    icon: 'icon-icon_xinyong_xianxing_jijin-1',
    color: '#13b8aa',
    path: '/sys/basic'
  },
  {
    key: 'monitor',
    name: '污染源在线监控系统',
    icon: 'icon-icon_xinyong_xianxing_jijin-288',
    color: '#f56c6c',
    path: '/sys/monitor'
  },
  {
    key: 'zhihui',
    name: '污染源智慧运维系统',
    path: '/sys/wisdom'
  },
  {
    key: 'env',
    name: '环境移动执法系统',
    icon: 'icon-icon_xinyong_xianxing_jijin-',
    color: '#fc9637',
    path: '/sys/env'
  }
]
const FORM_SIZE = 'mini'

export {
  SET_COMPANY,
  GET_COMPANY,
  SESSION,
  BASEURL,
  SYSTEMS,
  FORM_SIZE,
  SET_INFO_KEY,
  GET_INFO_KEY,
  SET_STATION,
  GET_STATION
}
