export default {
  path: '/sys/wisdom',
  meta: { title: '污染源智慧运维系统' },
  redirect: 'signin-record',
  component: () => import('@/views/wisdom/wisdomIndex.vue'),
  children: [
    {
      path: '/sys/wisdom/signin-record',
      meta: { title: '签到记录', left: true },
      component: () => import('@/views/wisdom/signinRecord/index.vue')
    },
    {
      path: '/sys/wisdom/signin-record/detail',
      meta: { title: '记录详情', hidden: true },
      component: () => import('@/views/wisdom/signinRecord/detail.vue')
    },
    {
      path: '/sys/wisdom/ledger-filter',
      meta: { title: '台账筛选', left: true },
      component: () => import('@/views/wisdom/ledgerFilter/index.vue')
    },
    {
      path: '/sys/wisdom/all-count',
      meta: { title: '统计总览' },
      component: () => import('@/views/wisdom/count/allCount')
    },
    {
      path: '/sys/wisdom/station-count',
      meta: { title: '排口台账统计', left: true },
      component: () => import('@/views/wisdom/count/stationCount')
    },
    {
      path: '/sys/wisdom/user-count',
      meta: { title: '人员台账统计' },
      component: () => import('@/views/wisdom/count/userCount')
    },
    {
      path: '/sys/wisdom/video-upload',
      meta: { title: '巡检视频上传', left: true },
      component: () => import('@/views/wisdom/video/index')
    },
  ]
}
