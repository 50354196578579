import Vue from 'vue'
import ElementUI from 'element-ui'
import VueLocalStorage from 'vue-localstorage'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/css/index.css'

import App from '@/layout/App.vue'
import router from '@/router/index'
import directive from '@/directive'
import store from '@/store/index'
import { eventBus } from '@/utils/eventBus'
import plugins from './plugins' // plugins
import './utils/permission'
import '@/assets/css/monitor.less'
import '@/assets/css/element.less'
import Dialog from '@/components/Dialog'
import Drawer from '@/components/Drawer'
import GuideIframe from '@/components/GuideIframe'
import IframeDialog from '@/components/IframeDialog'
import Card from '@/components/Card'
import { exportData, getConfig } from './utils/utils'
ElementUI.Dialog.props.lockScroll.default = false
Vue.config.productionTip = false
Vue.use(directive)
Vue.use(VueLocalStorage)
Vue.use(ElementUI, { size: 'mini', zIndex: 3000 })
Vue.use(plugins)

// 全局组件挂载
Vue.component('Dialog', Dialog)
Vue.component('Drawer', Drawer)
Vue.component('GuideIframe', GuideIframe)
Vue.component('IframeDialog', IframeDialog)
Vue.component('Card', Card)
Vue.prototype.$bus = eventBus
Vue.prototype.$exportData = exportData
Vue.prototype.$getConfig = getConfig
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
