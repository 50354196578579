<template>
  <iframe
    :src="`${$getConfig('guideBaseUrl')}/doc/?sys=${$route.path.split('/')[2]}&url=${$route.path.split('/')[3]}`"
    :style="{ width, height, border: 'none' }"
  ></iframe>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      // default: '80vh'
      default: '100vh'
    }
  },
  mounted() {
  }
}
</script>
