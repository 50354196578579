<template>
  <div v-show="visible" class="iframe-dialog">
    <div class="content">
      <!-- <el-button class="close-btn" type="primary" size="large" @click="oncClose">关闭</el-button> -->
      <div @click="oncClose" class="el-icon-close close-btn"></div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  mounted() { },
  methods: {
    oncClose() {
      this.$emit('update:visible', !this.visible)
    }
  }
}
</script>

<style lang="less" scoped>
.iframe-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}

.content {
  position: relative;

  .close-btn {
    position: absolute;
    right: 50px;
    top: 50px;


    background-color: rgba(255, 255, 255, 0.1);
    height: 55px;
    width: 55px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 30px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
</style>
