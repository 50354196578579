<template>
  <div class="drawer">
    <el-drawer v-bind="$attrs" :size="width" :title="title" :before-close="onClose">
      <div class="content" :class="{ 'fixed-bottom': fixedBottom }">
        <slot> </slot>
      </div>
      <div v-if="showFooter" class="footer">
        <div slot="footer">
          <el-button size="medium" @click="Cancel">{{ cancel_name }}</el-button>
          <el-button size="medium" type="primary" @click="Save">{{ confirm_name }}</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: '750px'
    },
    showFooter: {
      type: Boolean,
      default: true
    },
    cancel_name: {
      //取消按钮名称
      type: String,
      default: '取 消'
    },
    confirm_name: {
      //确定按钮名称
      type: String,
      default: '确 定'
    },
    title: {
      type: String
    },
    footerButtonAlign: {
      type: String,
      default: 'center'
    },
    fixedBottom: {
      type: Boolean,
      default: true
    }
  },
  computed: {},
  methods: {
    Cancel() {
      this.$emit('onCancel')
    },
    Save() {
      this.$emit('onSubmit')
    },
    onClose() {
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="less" scoped>
.drawer {
  /deep/ .el-drawer__header {
    margin-bottom: 16px;
  }
  /deep/ .el-drawer__body {
    display: flex;
    flex-direction: column;
    .content {
      padding: 0 20px;
      &.fixed-bottom {
        flex: 1;
        height: 100%;
        overflow-y: auto;
      }
    }
    .footer {
      margin: 10px auto;
    }
  }
}
</style>
