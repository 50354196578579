import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import permission from './modules/permission'
import monitor from './modules/monitor'
import getters from './getters'
import tabs from './modules/tabs'
import archive from "./modules/archive";
import bigScreen from "./modules/bigScreen";
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    user,
    permission,
    tabs,
    archive,
    monitor,
    bigScreen
  },
  getters
})

export default store
