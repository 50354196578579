<template>
  <div class="card" :style="{ backgroundColor: backgroundColor ? backgroundColor : '' }">
    <div class="header" :style="{ backgroundColor: headerBackground ? headerBackground : '', ...headerStyle }">
      <div v-if="title" class="left" :style="{ ...titleStyle }">{{ title }}</div>
      <div class="right">
        <slot name="header-right"></slot>
      </div>
    </div>
    <div class="card-body" :style="{ padding }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  computed: {},
  props: {
    title: {
      type: [String, Boolean],
      default: false
    },
    backgroundColor: {
      type: [String, Boolean],
      default: false
    },
    headerBackground: {
      type: [String, Boolean],
      default: false
    },
    padding: {
      type: String,
      default: '5px'
    },
    headerStyle: {
      type: Object
    },
    titleStyle: {
      type: Object
    }
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="less" scoped>
@import url('@/assets/css/monitor.less');
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  color: #fff;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 5px;
    box-sizing: border-box;
    position: relative;
    .left {
      font-size: 15px;
      &::before {
        content: ' ';
        border-left: 3px solid @border-color;
        padding-right: 8px;
      }
    }
    .right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      margin-right: 20px;
    }
  }
  .card-body {
    flex: 1;
  }
}
</style>
