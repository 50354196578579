import config from '@/config'
import store from '@/store'
import { Message } from 'element-ui'
import dayjs from 'dayjs'

// 根据id查找上级节点
export const findParentNodes = (treeData, nodeId) => {
  // 用于存储父级节点的数组
  const parentNodes = []
  // 递归函数，用于在树中查找节点
  function findNode(node, nodeId, parents) {
    if (node.id === nodeId) {
      // 找到目标节点，将当前父级节点数组添加到结果中
      parentNodes.push(...parents, node)
    } else if (node.child) {
      // 递归查找子节点
      for (const childNode of node.child) {
        findNode(childNode, nodeId, [...parents, node])
      }
    }
  }
  // 遍历树的顶层节点
  for (const rootNode of treeData) {
    findNode(rootNode, nodeId, [])
  }
  // 返回包含目标节点的每个父级节点的数组
  return parentNodes
}

export const downloadFile = (url, target = '_self', fileName) => {
  let link = document.createElement('a')
  link.style.display = 'none'
  // link.href = import.meta.env.VITE_DOWNLOAD + url
  link.href = config.storage + url
  if (fileName) link.download = fileName
  link.setAttribute('target', target)
  document.body.appendChild(link)
  link.click()
}

export const exportData = (api, type = '_self', msg = '暂无可导出数据') => {
  if (!(api instanceof Promise)) return Message.error('导出接口不正确')
  if (!api) return Message.error('导出方法不能为空')
  return new Promise((resolve, reject) => {
    api
      .then((res) => {
        if (res.url && typeof res.url === 'string') {
          downloadFile(res.url, type)
          resolve(res)
        } else if (res && typeof res === 'string') {
          downloadFile(res, type)
          resolve(res)
        } else {
          Message.warning(msg)
        }
      })
      .catch((err) => reject(err))
  })
}
// 获取日期时间
export function formatDate(type = 'date', num = 0) {
  const date = new Date()
  date.setDate(date.getDate() + num)
  const year = date.getFullYear()
  const month = padZero(date.getMonth() + 1)
  const day = padZero(date.getDate())
  const hours = padZero(date.getHours())
  const minutes = padZero(date.getMinutes())
  const seconds = padZero(date.getSeconds())
  if (type == 'date') {
    return `${year}-${month}-${day}`
  } else if (type == 'datetime') {
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }
}
// 补零函数，用于保证日期、月份、小时、分钟、秒钟都是两位数
function padZero(num) {
  return num.toString().padStart(2, '0')
}

//获取月份日期
export function getMonthRange(monthOffset = 0) {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()

  // 如果传入 -1，则获取上个月份的第一天和最后一天
  if (monthOffset === -1) {
    const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1
    const lastYear = currentMonth === 0 ? currentYear - 1 : currentYear
    const firstDay = new Date(lastYear, lastMonth, 1)
    const lastDay = new Date(currentYear, currentMonth, 0)
    return {
      firstDay: formatMonthDate(firstDay),
      lastDay: formatMonthDate(lastDay)
    }
  }

  // 否则获取当前月份的第一天和最后一天
  const firstDay = new Date(currentYear, currentMonth + monthOffset, 1)
  const lastDay = new Date(currentYear, currentMonth + monthOffset + 1, 0)
  return {
    firstDay: formatMonthDate(firstDay),
    lastDay: formatMonthDate(lastDay)
  }
}

// 格式化日期为 "YYYY-MM-DD" 格式
function formatMonthDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

// 创建excel表格并导出
export function exportToExcel(data, headers, filename = 'excel') {
  const csv = convertToCSV(data, headers) // 将数据和表头转换为 CSV 格式
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
  const link = document.createElement('a')
  if (link.download !== undefined) {
    const url = URL.createObjectURL(csvData)
    link.setAttribute('href', url)
    link.setAttribute('download', `${filename}.csv`)
    link.style.visibility = 'hidden'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
}
// 将数据和表头转换为 CSV 格式
function convertToCSV(data, headers) {
  const separator = ','
  const headerRow = headers.join(separator)
  const dataRows = data.map((row) => headers.map((key) => row[key]).join(separator))
  const csvContent = headerRow + '\n' + dataRows.join('\n')
  return csvContent
}

export const listAuth = (auth) => {
  const permissions = store.state.permission.authoritys || []
  const hasPermissions = permissions.some((item) => item === auth)
  return new Promise((resolve, reject) => {
    hasPermissions ? resolve() : reject()
  })
}

// 获取配置信息
export const getConfig = (field) => {
  return Object.keys(config).length ? config[field] : null
}

// 判断日期不超过七天
// 比较两个日期是否不超过 7 天
export function isWithinSevenDays(date1, date2) {
  console.log(date1, date2)
  // 将日期字符串解析为 Day.js 对象
  const start = dayjs(date1)
  const end = dayjs(date2)

  // 计算两个日期之间的天数差
  const daysDifference = end.diff(start, 'day')

  // 检查天数差是否小于等于 7
  return daysDifference >= 0 && daysDifference <= 7
}
