import { reqGet, reqPost, reqUpload } from '@/utils/request'
import { BASEURL } from '@/constant'

// 树状企业列表
const comEnterpriseList = (param) => reqGet(BASEURL + '/common/enterprise/list', param)
const comOutLetList = (param) => reqGet(BASEURL + '/common/outlet/list', param)

// 文件上传
const comUploadImage = (param) => reqUpload(BASEURL + '/common/upload/image', param)
const comUploadVideo = (param) => reqUpload(BASEURL + '/common/upload/video', param)
const comUploadFile = (param) => reqUpload(BASEURL + '/common/upload/file', param)
const comUploadUp = (param) => reqUpload(BASEURL + '/common/upload/up', param)

// 上传附件
const comUploadAnnex = (param) => reqPost(BASEURL + '/common/func/annex', param)

// 流域选项
const basinList = (param) => reqGet(BASEURL + '/common/basinList', param)
// 台账类型
const comLedger = (param) => reqGet(BASEURL + '/common/form/category', param)
// 设施信息标识
const comFacilityCode = (param) => reqGet(BASEURL + '/common/facility/code', param)
const comFacilityType = (param) => reqGet(BASEURL + '/common/facility/type', param)
// 排口列表
const comStation = (param) => reqGet(BASEURL + '/common/station', param)
// 单位列表
const comUnit = (param) => reqGet(BASEURL + '/common/unit', param)
// 试剂类型
const reagentType = (param) => reqGet(BASEURL + '/common/reagent/type', param)
// 设备协议
const comProtocol = (param) => reqGet(BASEURL + '/common/protocol', param)
// 设备类型
const comEquipmentType = (param) => reqGet(BASEURL + '/common/equipment/type', param)
// 辅助设备类型
const comAssistType = (param) => reqGet(BASEURL + '/common/assist/type', param)
// 水采仪类型
const comHydraulicType = (param) => reqGet(BASEURL + '/common/hydraulic/type', param)
// 运维企业
const commonOperationList = (param) => reqGet(BASEURL + '/common/operation/list', param)
const commonDate = (param) => reqGet(BASEURL + '/common/date', param) // 根据年份选择周月季度年
// 角色列表
const commonRoleList = (param) => reqGet(BASEURL + '/common/role/list', param) // 根据年份选择周月季度年

export {
  commonRoleList,
  commonDate,
  commonOperationList,
  comAssistType,
  comHydraulicType,
  comProtocol,
  comEquipmentType,
  reagentType,
  comEnterpriseList,
  comUploadImage,
  comUploadVideo,
  comUploadFile,
  basinList,
  comLedger,
  comOutLetList,
  comFacilityCode,
  comFacilityType,
  comStation,
  comUnit,
  comUploadAnnex,
  comUploadUp
}
//公共
// 系统编码
export const commonMonitor = (param) => reqGet(BASEURL + '/common/monitor', param)
// 厂商类型
export const commonManufacturer = (param) => reqGet(BASEURL + '/common/manufacturer', param)
// 分析方法
export const commonAnalytical = (param) => reqGet(BASEURL + '/common/analytical', param)
// 仪器类型
export const commonInstrument = (param) => reqGet(BASEURL + '/common/instrument', param)
// 仪器信息类型
export const commonInfoType = (param) => reqGet(BASEURL + '/common/info_type', param)
// 设备类型
export const commonDeviceType = (param) => reqGet(BASEURL + '/common/deviceType', param)
// 区域列表
export const commonRegion = (param) => reqGet(BASEURL + '/common/region', param)
//可选权限
export const commonPermission = (param) => reqGet(BASEURL + '/common/permission', param)
//可选菜单
export const commonMenu = (param) => reqGet(BASEURL + '/common/menu', param)
// 可选用户
export const commonOptional_user = (param) => reqGet(BASEURL + '/common/optional_user', param)
export const commonEnterpriseFunc = (param) => reqGet(BASEURL + '/common/enterprise/func', param)
// 审核基础步骤
export const commonAuditWorkflow = (param) => reqGet(BASEURL + '/common/audit/workflow', param)
// 区域
export const commonRegionData = (param) => reqGet(BASEURL + '/common/region/data', param)
// 行业信息
export const commonIndustryData = (param) => reqGet(BASEURL + '/common/industry/data', param)
// 删除附件
export const commonFuncAnnexDelete = (param) => reqGet(BASEURL + '/common/func/annex-delete', param)
// 版本记录
export const commonVersion = (param) => reqGet(BASEURL + '/common/version', param)
// 修改个人已确认版本
export const commonVersionUpdate = (param) => reqGet(BASEURL + '/common/version-update', param)
// 证件类型
export const commonCardType = (param) => reqGet(BASEURL + '/common/card/type', param)

// 大屏区域获取
export const commonOutletListRegion = (param) => reqGet(BASEURL + '/common/outlet/list-region', param)
// 数据标识
export const commonMonitorFlag = (param) => reqGet(BASEURL + '/common/monitor/flag', param)
// 预警通知列表
export const commonCommonEarly = (param) => reqGet(BASEURL + '/common/Common/early', param)
// 小程序权限
export const commonPermissionApp = (param) => reqGet(BASEURL + '/common/permissionApp', param)
// 易耗品类型
export const commonConsumableType = (param) => reqGet(BASEURL + '/common/consumable/type', param)
