import constantRoutes from '@/router/authorize'
import { getMenus } from '@/api/auth'
import allRoutes from '@/router/allRoutes'
import { routeInit, setDefaultRoute } from '@/utils/contrast-router'
const permission = {
  state: {
    routes: [],
    menus: [],
    addRoutes: [],
    defaultRoutes: [],
    authoritys: []
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes
      state.routes = constantRoutes.concat(routes)
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    SET_AUTHORITYS: (state, authoritys) => {
      state.authoritys = authoritys
    }
    // SET_DEFAULT_ROUTES: (state, routes) => {
    //   state.defaultRoutes = constantRoutes.concat(routes)
    // }
  },
  actions: {
    // 生成路由
    GenerateRoutes({ commit }) {
      return new Promise((resolve, redirect) => {
        // 向后端请求路由数据
        getMenus().then((menus) => {
          if (menus.length === 0) {
            resolve({
              path: '/sys',
              component: () => import('@/layout/LayoutContent.vue'),
              children: []
            })
            return
          }
          const data = JSON.parse(JSON.stringify(menus.menu))
          commit('SET_AUTHORITYS', menus.authority)
          routeInit(allRoutes, data)
          setDefaultRoute(allRoutes, data)

          const routes = {
            path: '/sys',
            component: () => import('@/layout/LayoutContent.vue'),
            children: allRoutes
          }
          // const rewriteRoutes = filterAsyncRouter(data, false, true)

          commit('SET_MENUS', data)
          // commit('SET_ROUTES', routes)
          // commit('SET_DEFAULT_ROUTES', sidebarRoutes)
          // setTimeout(()=>{ 
          // },100)
          resolve(routes)
        })
      })
    }
  }
}

export default permission
