export default {
  path: '/sys/basic',
  meta: { title: '基础数据管理系统' },
  redirect: '/sys/basic/annexType',
  component: () => import('@/views/basic/BasicIndex.vue'),
  children: [
    // 基础字典
    {
      path: '/sys/basic/annexType',
      meta: { title: '附件类型' },
      component: () => import('@/views/basic/dictionary/annexType/AnnexType.vue')
    },
    {
      path: '/sys/basic/basin',
      meta: { title: '流域管理' },
      component: () => import('@/views/basic/dictionary/basin/Basin.vue')
    },
    {
      path: '/sys/basic/analytical',
      meta: { title: '分析方法' },
      component: () => import('@/views/basic/dictionary/analytical/Analytical.vue')
    },
    {
      path: '/sys/basic/factor',
      meta: { title: '传输因子' },
      component: () => import('@/views/basic/dictionary/factor/Factor.vue')
    },
    {
      path: '/sys/basic/industry',
      meta: { title: '行业信息' },
      component: () => import('@/views/basic/dictionary/industry/Industry.vue')
    },
    {
      path: '/sys/basic/monitorFlag',
      meta: { title: '数据标记' },
      component: () => import('@/views/basic/dictionary/monitorFlag/MonitorFlag.vue')
    },
    {
      path: '/sys/basic/unit',
      meta: { title: '单位管理' },
      component: () => import('@/views/basic/dictionary/unit/Unit.vue')
    },
    {
      path: '/sys/basic/ingredient',
      meta: { title: '生产原料' },
      component: () => import('@/views/basic/dictionary/ingredient/Ingredient.vue')
    },
    {
      path: '/sys/basic/product',
      meta: { title: '产品信息' },
      component: () => import('@/views/basic/dictionary/product/Product.vue')
    },
    {
      path: '/sys/basic/audit',
      meta: { title: '审核信息配置' },
      component: () => import('@/views/basic/dictionary/audit/Audit.vue')
    },
    {
      path: '/sys/basic/deviceType',
      meta: { title: '仪器管理' },
      component: () => import('@/views/basic/dictionary/deviceType/DeviceType.vue')
    },
    {
      path: '/sys/basic/equipmentType',
      meta: { title: '数采仪信息' },
      component: () => import('@/views/basic/dictionary/equipmentType/EquipmentType.vue')
    },
    {
      path: '/sys/basic/facilityType',
      meta: { title: '治理设施信息' },
      component: () => import('@/views/basic/dictionary/facilityType/FacilityType.vue')
    },
    {
      path: '/sys/basic/consumables',
      meta: { title: '易耗品信息' },
      component: () => import('@/views/basic/dictionary/consumables/Consumables.vue')
    },
    {
      path: '/sys/basic/instrumentInfo',
      meta: { title: '仪器参数' },
      component: () => import('@/views/basic/dictionary/instrumentInfo/InstrumentInfo.vue')
    },
    {
      path: '/sys/basic/hydraulic',
      meta: { title: '水采仪类型' },
      component: () => import('@/views/basic/dictionary/hydraulic/Hydraulic.vue')
    },
    {
      path: '/sys/basic/assist',
      meta: { title: '辅助设备类型' },
      component: () => import('@/views/basic/dictionary/assist/Assist.vue')
    },
    {
      path: '/sys/basic/monitorType',
      meta: { title: '系统编码' },
      component: () => import('@/views/basic/dictionary/monitorType/MonitorType.vue')
    },
    {
      path: '/sys/basic/operationInfo',
      meta: { title: '排口运维企业' },
      component: () => import('@/views/basic/dictionary/operationInfo/OperationInfo.vue')
    },
    {
      path: '/sys/basic/facility',
      meta: { title: '治理设施标识' },
      component: () => import('@/views/basic/dictionary/facility/Facility.vue')
    },
    {
      path: '/sys/basic/manufacturer',
      meta: { title: '厂商名称' },
      component: () => import('@/views/basic/dictionary/manufacturer/Manufacturer.vue')
    },
    {
      path: '/sys/basic/reagentType',
      meta: { title: '试剂类型信息' },
      component: () => import('@/views/basic/dictionary/reagentType/ReagentType.vue')
    },
    {
      path: '/sys/basic/templateFault',
      meta: { title: '设备故障模板' },
      component: () => import('@/views/basic/dictionary/templateFault/TemplateFault.vue')
    },
    {
      path: '/sys/basic/instrumentInfoType',
      meta: { title: '站房仪器信息分类' },
      component: () => import('@/views/basic/dictionary/instrumentInfoType/InstrumentInfoType.vue')
    },
    {
      path: '/sys/basic/instrumentType',
      meta: { title: '站房仪器类型' },
      component: () => import('@/views/basic/dictionary/instrumentType/InstrumentType.vue')
    },
    {
      path: '/sys/basic/stationCatrgory',
      meta: { title: '运维台账总类' },
      component: () => import('@/views/basic/dictionary/stationCatrgory/StationCatrgory.vue')
    },
    {
      path: '/sys/basic/rateFactor',
      meta: { title: '有效率因子' },
      component: () => import('@/views/basic/dictionary/rateFactor/RateFactor.vue')
    },
    {
      path: '/sys/basic/flagNotice',
      meta: { title: '数据标识' },
      component: () => import('@/views/basic/dictionary/flagNotice/FlagNotice.vue')
    },
    {
      path: '/sys/basic/information',
      meta: { title: '资料管理' },
      component: () => import('@/views/basic/dictionary/information/Information.vue')
    },
    {
      path: '/sys/basic/car',
      meta: { title: '车辆管理' },
      component: () => import('@/views/basic/dictionary/car/Car.vue')
    },
    //系统功能
    {
      path: '/sys/basic/sysMenu',
      meta: { title: '系统功能' },
      component: () => import('@/views/basic/sys/sysMenu/SysMenu.vue')
    },
    //域名管理
    {
      path: '/sys/basic/ym',
      meta: { title: '系统功能' },
      component: () => import('@/views/basic/sys/ym/ym.vue')
    },
    //系统设置
    {
      path: '/sys/basic/system',
      meta: { title: '系统设置' },
      component: () => import('@/views/basic/sys/system/System.vue')
    },
    //菜单权限
    {
      path: '/sys/basic/sysPermission',
      meta: { title: '菜单权限' },
      component: () => import('@/views/basic/sys/sysPermission/SysPermission.vue')
    },
    {
      path: '/sys/basic/globalSetting',
      meta: { title: '全局设置' },
      component: () => import('@/views/basic/sys/global/Global.vue')
    },
    //监控对象
    {
      path: '/sys/basic/enterprise',
      meta: { title: '企业信息管理' },
      component: () => import('@/views/basic/monitor/enterprise/Enterprise.vue')
    },
    //用户权限
    {
      path: '/sys/basic/terminalPermission',
      meta: { title: '终端权限配置' },
      component: () => import('@/views/basic/permission/terminalPermission/TerminalPermission.vue')
    },
    {
      path: '/sys/basic/operationPermission',
      meta: { title: '运维权限配置' },
      component: () => import('@/views/basic/permission/operationPermission/OperationPermission.vue')
    },
    {
      path: '/sys/basic/environmentPermission',
      meta: { title: '环保机构权限配置' },
      component: () => import('@/views/basic/permission/environmentPermission/EnvironmentPermission.vue')
    },
    {
      path: '/sys/basic/terminalDispose',
      meta: { title: '终端排口配置' },
      component: () => import('@/views/basic/permission/terminalDispose/TerminalDispose.vue')
    },
    {
      path: '/sys/basic/operationDispose',
      meta: { title: '运维排口配置' },
      component: () => import('@/views/basic/permission/operationDispose/OperationDispose.vue')
    },
    {
      path: '/sys/basic/environmentDispose',
      meta: { title: '环保机构排口配置' },
      component: () => import('@/views/basic/permission/environmentDispose/EnvironmentDispose.vue')
    },
    {
      path: '/sys/basic/role',
      meta: { title: '角色管理' },
      component: () => import('@/views/basic/permission/role/Role.vue')
    },
    {
      path: '/sys/basic/user',
      meta: { title: '用户管理' },
      component: () => import('@/views/basic/permission/user/User.vue')
    },
    {
      path: '/sys/basic/organize',
      meta: { title: '组织管理' },
      component: () => import('@/views/basic/permission/organize/Organize.vue')
    },
    //科室管理
    {
      path: '/sys/basic/environment',
      meta: { title: '环保机构' },
      component: () => import('@/views/basic/dictionary/environment/Environment.vue')
    },
    //运维信息
    {
      path: '/sys/basic/agency',
      meta: { title: '运维企业' },
      component: () => import('@/views/basic/operation/agency/Agency.vue')
    },
    {
      path: '/sys/basic/agencyTerminal',
      meta: { title: '终端企业' },
      component: () => import('@/views/basic/operation/agencyTerminal/AgencyTerminal.vue')
    },
    {
      path: '/sys/basic/video',
      meta: { title: '视频监控' },
      component: () => import('@/views/basic/video/video/index.vue')
    },
    {
      path: '/sys/basic/setting',
      meta: { title: '服务信息配置' },
      component: () => import('@/views/basic/video/setting/index.vue')
    },
    {
      path: '/sys/basic/update-version',
      meta: { title: '版本更新' },
      component: () => import('@/views/basic/updateVers/index.vue')
    },
    {
      path: '/sys/basic/sampleForm',
      meta: { title: '标准样品证书编号' },
      component: () => import('@/views/basic/dictionary/sampleForm/index.vue')
    }
  ]
}
