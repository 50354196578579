import { getItem, setItem, removeItem } from './storage'
import { SESSION } from '@/constant'

export function getSession() {
  return getItem(SESSION)
}

export function setSession(session) {
  setItem(SESSION, session)
}

export function removeSession() {
  return new Promise((resolve, reject) => {
    removeItem(SESSION)
    resolve()
  }).catch((error) => {
    reject(error)
  })
}
