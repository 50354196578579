import { loginApi, logoutApi, getInfo } from '@/api/auth'
import { getSession, setSession, removeSession } from '@/utils/auth'
import config from '@/config'
import { removeItem } from '@/utils/storage'

const user = {
  state: {
    session: getSession(),
    name: '',
    avatar: '',
    roles: [],
    permissions: [],
    info: {}
  },

  mutations: {
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_SESSION: (state, session) => {
      state.session = session
    },
    SET_NAME: (state, name) => {
      state.name = name
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    // SET_ROLES: (state, roles) => {
    //   state.roles = roles
    // },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions
    }
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      const account = userInfo.account.trim()
      const password = userInfo.password
      return new Promise((resolve, reject) => {
        loginApi({ account, password })
          .then((res) => {
            setSession(res.session)
            commit('SET_SESSION', res.session)
            resolve()
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            const user = res.user
            // res.roles = [1, 2]
            const avatar =
              user.avatar_url == '' || user.avatar_url == null
                ? require('@/assets/img/profile.jpg')
                : config.storageHost + user.avatar_url
            // if (res.roles && res.roles.length > 0) {
            //   // 验证返回的roles是否是一个非空数组
            //   commit('SET_ROLES', res.roles)
            //   // commit('SET_PERMISSIONS', res.permissions)
            // } else {
            //   commit('SET_ROLES', ['ROLE_DEFAULT'])
            // }
            commit('SET_NAME', user.userName)
            commit('SET_AVATAR', avatar)
            commit('SET_INFO', user)
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 退出系统
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logoutApi({ session: state.session })
          .then(() => {
            commit('SET_SESSION', '')
            commit('SET_ROLES', [])
            commit('SET_PERMISSIONS', [])
            removeSession().then(() => {
              const items = ['_AMap_WebGLRender', 'regions', 'rememberForm', 'remember_me', 'realMonitorId']
              Object.keys(localStorage).forEach((item) => {
                if (!items.includes(item) && item != 'version') removeItem(item)
              })
              removeItem('realMonitorId')
              resolve()
            })
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_SESSION', '')
        removeSession()
        resolve()
      })
    }
  }
}

export default user
