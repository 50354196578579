import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'
import md5 from 'js-md5'
import config from '@/config'
import { getSession } from '@/utils/auth'
import { Message } from 'element-ui'
import store from '@/store'

axios.defaults.baseURL = config.host
axios.interceptors.request.use(
  (config) => {
    if (config.method === 'post') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 添加响应拦截器
axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * 网络请求异常处理
 * @param {*} error
 */
const responseError = (error) => {
  if (error.response) {
    const response = error.response
  } else {
  }
}

/**
 * 请求成功处理
 * @param {*} res
 * @param {*} resolve
 * @param {*} reject
 */
const responseSuccess = (response, resolve, reject, needReject) => {
  const res = response.data
  if (res.code === 100) {
    resolve(res.data)
  } else if (res.code === 101) {
    // store.dispatch('LogOut').then(() => {
    //   Message.error(res.message)
    //   location.href = '/';
    //   reject(res)
    // })

    // TODO:
    // Message.error(res.message)

    Message.error(res.message)
    localStorage.removeItem('session')
    location.href = '/'

    // reject(res)
  } else {
    // reject(res)
    needReject && reject(res)
    Message.error(res.message)
  }
}

/**
 * @desc 获取get请求
 * @param {*} url
 * @param {*} query
 */
export const reqGet = (url, query = {}) => {
  const { needReject = false } = query
  // const session = Vue.localStorage.get(md5("session"));
  const session = getSession()
  if (session) {
    query.session = session
  }
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      params: query,
      method: 'get'
    })
      .then((res) => responseSuccess(res, resolve, reject, needReject))
      .catch((error) => responseError(error))
  })
}

/**
 * @desc 获取get请求
 * @param {*} url
 * @param {*} query
 */
export const reqPost = (url, params = {}) => {
  const { needReject = false } = params
  // const session = Vue.localStorage.get(md5('session'))
  const sessions = getSession()
  if (sessions) {
    params.session = sessions
  }
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      data: Qs.stringify(params),
      method: 'post'
    })
      .then((res) => responseSuccess(res, resolve, reject, needReject))
      .catch((error) => responseError(error))
  })
}

/**
 * @desc 文件上传
 * @param {*} url
 * @param formData
 */
export const reqUpload = (url, formData) => {
  let headers = {
    'Content-Type': 'multipart/form-data'
  }
  return new Promise((resolve, reject) => {
    if (!formData.get('noAuth')) {
      const sessions = getSession()
      if (sessions) {
        formData.append('session', sessions)
        axios({
          url: url,
          method: 'POST',
          headers: headers,
          data: formData
        })
          .then((res) => responseSuccess(res, resolve, reject))
          .catch((error) => responseError(error))
      }
    } else {
      axios({
        url: url,
        method: 'POST',
        headers: headers,
        data: formData
      })
        .then((res) => responseSuccess(res, resolve, reject))
        .catch((error) => responseError(error))
    }
  })
}

/**
 * @desc 台风路径获取
 * @param {*} url
 */
export const reqTyphoon = (url) => {
  return new Promise((resolve, reject) => {
    axios({
      url: url,
      method: 'get'
    })
      .then((res) => {
        if (res.status == 200) {
          resolve(res.data)
        } else {
          reject(res.data)
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
}
