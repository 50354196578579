import { setItem, getItem } from '@/utils/storage'

export default {
  namespaced: true,
  state: {
    city_id: getItem('city_id')
  },
  mutations: {
    SET_CITY_ID(state, value) {
      state.city_id = value
      setItem('city_id', value)
    }
  }
}
