<template>
  <div class="popup" :class="{ 'clear-padding': clearPadding }">
    <el-dialog
      v-bind="$attrs"
      :title="title"
      :close-on-click-modal="false"
      @close="onClose"
      :custom-class="hideHeader ? 'no-header-dialog' : ''"
    >
      <div class="content">
        <button
          v-show="hideHeader"
          type="button"
          @click="onClose"
          aria-label="Close"
          class="el-dialog__headerbtn right-close-btn"
        >
          <span class="el-dialog__close el-icon close-text">关闭</span>
        </button>
        <slot> </slot>
      </div>
      <div class="footer" :style="{ textAlign: footerButtonAlign }">
        <div v-if="showFooter" slot="footer" class="dialog-footer">
          <el-button @click="Cancel">{{ cancel_name }}</el-button>
          <el-button type="primary" @click="Save">{{ confirm_name }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    showFooter: {
      type: Boolean,
      default: true
    },
    cancel_name: {
      //取消按钮名称
      type: String,
      default: '取 消'
    },
    confirm_name: {
      //确定按钮名称
      type: String,
      default: '确 定'
    },
    title: {
      type: String
    },
    footerButtonAlign: {
      type: String,
      default: 'center'
    },
    hideHeader: {
      type: Boolean,
      default: false
    },
    clearPadding: {
      type: Boolean,
      default: false
    }
  },
  computed: {},
  methods: {
    Cancel() {
      this.$emit('onReset')
    },
    Save() {
      this.$emit('onSubmit')
    },
    onClose() {
      this.$emit('update:visible')
      this.$emit('onClose')
    }
  }
}
</script>

<style lang="less" scoped>
.popup {
  width: 550px;
  .el-dialog {
    z-index: 11000;
    background-color: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(17, 130, 251, 0.5);
    border-radius: 5px;
    margin-top: 10% !important;
  }
  .el-dialog__header {
    width: 100%;
    height: 40px;
    line-height: 38px;
    border-bottom: 1px solid #f6f7f9;
    box-sizing: border-box;
    padding: 0 20px;
    font-size: 16px;
  }

  .content {
    flex: 1;
    overflow-y: auto;
    padding: 1px 20px;
    position: relative;
    .right-close-btn {
      position: absolute;
      right: 14px;
      top: 10px;
      width: 50px;
      height: 35px;
      line-height: 35px;
      box-shadow: 0 0 6px rgba(74, 255, 120, 0.8);
      border-radius: 4px;
      span {
        color: #dfdfdf;
        &:hover {
          color: #268145;
        }
      }
    }
  }

  .footer {
    margin-top: 10px;
  }

  .el-dialog__close.el-icon.el-icon-close {
    font-size: 20px;
    width: 17px;
    height: 17px;
  }
  .el-button.el-button--default {
    width: 88px;
  }
  .el-button.el-button--primary {
    width: 88px;
  }
  /deep/ .el-dialog__body {
    display: flex;
    flex-direction: column;
    max-height: 850px;
    padding: 10px 0;
  }
  .el-dialog__headerbtn {
    top: 4px;
  }
  .el-dialog__title {
    color: #3c4354;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    line-height: 16px;
  }
}

.clear-padding /deep/ .el-dialog__body {
  padding: 0;
}
.clear-padding /deep/ .content {
  padding: 0;
}
.clear-padding /deep/ .el-dialog {
  background-color: transparent;
  box-shadow: none;
}
// 隐藏头部的样式
/deep/.el-dialog__wrapper .no-header-dialog .el-dialog__header {
  display: none;
}
</style>
