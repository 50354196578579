import { getItem, setItem } from '@/utils/storage'
import { SET_COMPANY, SET_INFO_KEY, SET_STATION } from '@/constant'

export default {
  state: {
    company: getItem(SET_COMPANY) || {},
    infoKey: getItem(SET_INFO_KEY) || 'company',
    station: getItem(SET_STATION) || {}
  },

  mutations: {
    SET_COMPANY: (state, info) => {
      state.company = info
      setItem(SET_COMPANY, info)
    },
    SET_INFO_KEY: (state, key) => {
      state.infoKey = key
      setItem(SET_INFO_KEY, key)
    },
    SET_STATION: (state, station) => {
      state.station = station
      setItem(SET_STATION, station)
    }
  }
}
