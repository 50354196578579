export default {
  path: '/sys/monitor',
  meta: { title: '污染源在线监控系统' },
  redirect: '/sys/monitor/realData',
  component: () => import('@/views/monitor/MonitorIndex.vue'),
  children: [
    {
      path: '/sys/monitor/realData',
      meta: { title: '实时数据' },
      component: () => import('@/views/monitor/realMonitor/realData/RealData.vue')
    },
    {
      path: '/sys/monitor/myJob',
      meta: { title: '预警通知' },
      component: () => import('@/views/monitor/realMonitor/myJob/MyJob.vue')
    },
    {
      path: '/sys/monitor/early',
      meta: { title: '预警通知' },
      component: () => import('@/views/monitor/realMonitor/myJob/MyJob.vue')
    },
    {
      path: '/sys/monitor/GIS',
      meta: { title: '监控分布图' },
      component: () => import('@/views/monitor/GIS/monitorDistribution/MonitorDistribution.vue')
    },
    {
      path: '/sys/monitor/emissions',
      meta: { title: '排放量分析图' },
      component: () => import('@/views/monitor/GIS/emissions/Emissions')
    },
    {
      path: '/sys/monitor/remote/analyzer',
      meta: { title: '分析仪', left: true },
      component: () => import('@/views/monitor/remote/analyzer/index.vue')
    },
    {
      path: '/sys/monitor/remote/data-sampler',
      meta: { title: '数采仪', left: true },
      component: () => import('@/views/monitor/remote/dataSampler/index.vue')
    },
    {
      path: '/sys/monitor/remote/assist-device',
      meta: { title: '辅助设备', left: true },
      component: () => import('@/views/monitor/remote/assistDevice/index.vue')
    },
    {
      path: '/sys/monitor/remote/water-sampler',
      meta: { title: '水采仪', left: true },
      component: () => import('@/views/monitor/remote/waterSampler/index.vue')
    },
    {
      path: '/sys/monitor/video/real',
      meta: { title: '实时视频', left: true },
      component: () => import('@/views/monitor/video/real/index.vue')
    },
    {
      path: '/sys/monitor/video/history',
      meta: { title: '历史视频', left: true },
      component: () => import('@/views/monitor/video/history/index.vue')
    },
    {
      path: '/sys/monitor/app/params',
      meta: { title: '多参数曲线', left: true },
      component: () => import('@/views/monitor/dataApp/params/index.vue')
    },
    {
      path: '/sys/monitor/app/out-count',
      meta: { title: '排放量统计' },
      component: () => import('@/views/monitor/dataApp/outCount/index.vue')
    },
    {
      path: '/sys/monitor/app/single',
      meta: { title: '单站数据对比', left: true },
      component: () => import('@/views/monitor/dataApp/single/index.vue')
    },
    {
      path: '/sys/monitor/app/out-analysis',
      meta: { title: '排放量分析' },
      component: () => import('@/views/monitor/dataApp/outAnalysis/index.vue')
    },
    {
      path: '/sys/monitor/app/annual-trend',
      meta: { title: '年趋势分析', left: true },
      component: () => import('@/views/monitor/dataApp/annualTrend/index.vue')
    },
    {
      path: '/sys/monitor/app/over-count',
      meta: { title: '超标统计', left: true },
      component: () => import('@/views/monitor/dataApp/overCount/index.vue')
    },
    {
      path: '/sys/monitor/app/over-time',
      meta: { title: '超标时间分布', left: true },
      component: () => import('@/views/monitor/dataApp/overTime/index.vue')
    },
    {
      path: '/sys/monitor/app/transfer',
      meta: { title: '传输有效率' },
      component: () => import('@/views/monitor/dataApp/transfer/index.vue')
    },
    {
      path: '/sys/monitor/runStatus',
      meta: { title: '运行状况', left: true },
      component: () => import('@/views/monitor/data/runStatus/RunStatus.vue')
    },
    {
      path: '/sys/monitor/oneRealData',
      meta: { title: '单站实时数据', left: true },
      component: () => import('@/views/monitor/data/realData/RealData.vue')
    },
    {
      path: '/sys/monitor/historyData',
      meta: { title: '单站历史数据', left: true },
      component: () => import('@/views/monitor/data/historyData/HistoryData.vue')
    },
    {
      path: '/sys/monitor/Regular',
      meta: { title: '单站常规报表', left: true },
      component: () => import('@/views/monitor/data/regular/Regular.vue')
    },
    {
      path: '/sys/monitor/enterpriseReport',
      meta: { title: '企业情况上报' },
      component: () => import('@/views/monitor/data/enterpriseReport/EnterpriseReport.vue')
    },
    {
      path: '/sys/monitor/operationReport',
      meta: { title: '运维情况上报' },
      component: () => import('@/views/monitor/data/operationReport/OperationReport.vue')
    },
    {
      path: '/sys/monitor/dataEntry',
      meta: { title: '数据录入', left: true },
      component: () => import('@/views/monitor/data/dataEntry/DataEntry.vue')
    },
    {
      path: '/sys/monitor/controlDataEntry',
      meta: { title: '质控数据录入', left: true },
      component: () => import('@/views/monitor/data/controlDataEntry/ControlDataEntry.vue')
    },
    // 审核管理
    {
      path: '/sys/monitor/audit/ledger',
      meta: { title: '台账审核' },
      component: () => import('@/views/monitor/audit/ledger/index.vue')
    },
    {
      path: '/sys/monitor/audit/reagent',
      meta: { title: '试剂审核' },
      component: () => import('@/views/monitor/audit/reagent/index.vue')
    },
    {
      path: '/sys/monitor/audit/user',
      meta: { title: '注册审核' },
      component: () => import('@/views/monitor/audit/user/index.vue')
    },
    {
      path: '/sys/monitor/audit/part',
      meta: { title: '配件审核' },
      component: () => import('@/views/monitor/audit/part/index.vue')
    }
  ]
}
