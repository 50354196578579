export default {
  path: '/sys/archive',
  meta: { title: '企业信息管理系统' },
  component: () => import('@/layout/Content.vue'),
  redirect: '/sys/archive/basic',
  children: [
    {
      path: '/sys/archive/basic',
      meta: { title: '基础信息' },
      component: () => import('@/views/archive/basic/index.vue')
    },
    {
      path: '/sys/archive/effluent',
      meta: { title: '废水排放口', guide: true },
      component: () => import('@/views/archive/effluent/index.vue')
    },
    {
      path: '/sys/archive/gas',
      meta: { title: '废气排放口' },
      component: () => import('@/views/archive/gas/index.vue')
    },
    {
      path: '/sys/archive/product',
      meta: { title: '主要产品' },
      component: () => import('@/views/archive/product/index.vue')
    },
    {
      path: '/sys/archive/material',
      meta: { title: '生产原料' },
      component: () => import('@/views/archive/material/index.vue')
    },
    {
      path: '/sys/archive/facility',
      meta: { title: '治理设施' },
      component: () => import('@/views/archive/facility/index.vue')
    },
    {
      path: '/sys/archive/dataLogger',
      meta: { title: '数采仪' },
      component: () => import('@/views/archive/dataLogger/index.vue')
    },
    {
      path: '/sys/archive/monitor',
      meta: { title: '在线监测仪' },
      component: () => import('@/views/archive/monitor/index.vue')
    },
    {
      path: '/sys/archive/water_sampler',
      meta: { title: '水采仪' },
      component: () => import('@/views/archive/waterSampler/index.vue')
    },
    {
      path: '/sys/archive/assist_device',
      meta: { title: '辅助设备' },
      component: () => import('@/views/archive/assistDevice/index.vue')
    },
    {
      path: '/sys/archive/allowDirty',
      meta: { title: '排污许可证' },
      component: () => import('@/views/archive/allowDirty/index.vue')
    },
    {
      path: '/sys/archive/supervisor',
      meta: { title: '环保监督员' },
      component: () => import('@/views/archive/supervisor/index.vue')
    },
    {
      path: '/sys/archive/EIAInfo',
      meta: { title: '环评信息' },
      component: () => import('@/views/archive/EIAInfo/index.vue')
    },
    {
      path: '/sys/archive/emergency',
      meta: { title: '应急预案' },
      component: () => import('@/views/archive/emergency/index.vue')
    },
    {
      path: '/sys/archive/stopRecord',
      meta: { title: '停产记录' },
      component: () => import('@/views/archive/stopRecord/index.vue')
    },
    {
      path: '/sys/archive/charge',
      meta: { title: '排污收费明细' },
      component: () => import('@/views/archive/charge/index.vue')
    },
    {
      path: '/sys/archive/report',
      meta: { title: '监测报告' },
      component: () => import('@/views/archive/report/index.vue')
    },
    {
      path: '/sys/archive/reagent',
      meta: { title: '排口试剂' },
      component: () => import('@/views/archive/reagent/index.vue')
    },
    {
      path: '/sys/archive/factor',
      meta: { title: '监测因子' },
      component: () => import('@/views/archive/factor/index.vue')
    },
    {
      path: '/sys/archive/fixed',
      meta: { title: '固定值因子参数' },
      component: () => import('@/views/archive/fixed/index.vue')
    },
    {
      path: '/sys/archive/standard',
      meta: { title: '因子排放标准' },
      component: () => import('@/views/archive/standard/index.vue')
    },
    {
      path: '/sys/archive/video',
      meta: { title: '视频监控接入' },
      component: () => import('@/views/archive/video/index.vue')
    },
    {
      path: '/sys/archive/ledger-device',
      meta: { title: '台账仪器' },
      component: () => import('@/views/archive/ledgerDevice/index.vue')
    },
    {
      path: '/sys/archive/sta-sample',
      meta: { title: '台账标准样品' },
      component: () => import('@/views/archive/StaSample/index.vue')
    },
    {
      path: '/sys/archive/consumable',
      meta: { title: '排口易耗品' },
      component: () => import('@/views/archive/consumable/index.vue')
    },
  ]
}
