// import { reqGet, reqPost, comUpload } from '@/utils/request'
import { reqGet, reqPost, reqUpload } from '@/utils/request'
import { BASEURL } from '@/constant'
// 登录
export const loginApi = (param) => reqPost(BASEURL + '/auth/login/backstage', param)
export const getInfo = (param) => reqGet(BASEURL + '/auth/account/detail', param)
export const logoutApi = (param) => reqPost(BASEURL + '/auth/logout/logout', param)
export const updateInfo = (param) => reqPost(BASEURL + '/web/auth/update', param)
export const updateAvatar = (param) => reqPost(BASEURL + '/web/auth/avatar_url', param)
export const updatePassword = (param) => reqPost(BASEURL + '/web/auth/password', param)
export const getMenus = (param) => reqGet(BASEURL + '/auth/account/administrator', param)

// 证件
export const cardListApi = (param) => reqGet(BASEURL + '/web/auth/card/list', param)
export const cardCreateApi = (param) => reqPost(BASEURL + '/web/auth/card/list-create', param)
export const cardUpdateApi = (param) => reqPost(BASEURL + '/web/auth/card/list-update', param)
export const cardRemoveApi = (param) => reqPost(BASEURL + '/web/auth/card/list-delete', param)

// 注册
export const registerApi = (param) => reqPost(BASEURL + '/auth/register/account', param)
export const registerOperaList = (param) => reqGet(BASEURL + '/auth/register/list-operation', param)
export const registerTerminalList = (param) => reqGet(BASEURL + '/auth/register/list-terminal', param)
export const registerAnnexList = (param) => reqGet(BASEURL + '/auth/register/list-annex', param)
export const registerRoleList = (param) => reqGet(BASEURL + '/auth/register/list-role', param)

export const registerFile = (param) => reqUpload(BASEURL + '/auth/register/file', param)

// 系统信息
export const authRegisterFilings = (param) => reqGet(BASEURL + '/auth/register/filings', param)
