export default {
  namespaced: true,
  state: {
    tabArr: [],
  },
  mutations: {
    SET_TABARR(state,value){
        state.tabArr=value
    },
  }
}
